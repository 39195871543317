<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import appConfig from "@/app.config";
import fieldSvc from "@/services/field";
import { toast } from "vue3-toastify";

/**
 * Customers component
 */
export default {
  page: {
    title: "Field",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Field",
      items: [
        {
          text: "Setting",
          href: "#",
        },
        {
          text: "Field",
          active: true,
        },
      ],
      columns: [
        {
          field: "uuid",
          hidden: true,
        },
        {
          label: "Field Name",
          field: "name",
        },
        {
          label: "Input Type",
          field: "inputType",
        },
        {
          label: "Field Type",
          field: "fieldType",
        },
        {
          label: "Status",
          field: "isActive",
        },
        {
          label: "Created Date",
          field: "createdAt",
        },
        {
          label: "Action",
          field: "button",
          html: true,
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      rows: [],
      showModalCreate: false,
      showModalEdit: false,
      submitted: false,
      field: {
        uuid: "",
        title: "",
        name: "",
        inputType: "",
        fieldType: "",
      },
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    field: {
      name: {
        required: helpers.withMessage("name is required", required),
      },
      inputType: {
        required: helpers.withMessage("input type is required", required),
      },
      fieldType: {
        required: helpers.withMessage("field type is required", required),
      },
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      fieldSvc.getAll().then((resp) => {
        if (resp.data.data === null) {
          return;
        }
        this.rows = resp.data.data;
      });
    },
    create() {
      this.field.uuid = "";
      this.field.name = "";
      this.field.inputType = "";
      this.field.fieldType = "";
      this.submitted = false;
      this.showModalCreate = true;
    },
    handleSubmit() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        const data = {
          name: this.field.name,
          inputType: this.field.inputType,
          fieldType: this.field.fieldType,
        };
        fieldSvc
          .create(data)
          .then((resp) => {
            if (resp.status === 200) {
              toast.success("Created Successfully", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            } else {
              toast.error("Created Failed", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            }
          })
          .catch((err) => {
            toast.error(err, {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          })
          .finally(() => {
            this.get();
            this.showModalCreate = false;
            this.submitted = false;
          });
      }
    },
    edit(data) {
      this.field.title = data.name;
      this.field.uuid = data.uuid;
      this.field.name = data.name;
      this.field.inputType = data.inputType;
      this.field.fieldType = data.fieldType;
      this.submitted = false;
      this.showModalEdit = true;
    },
    handleEditSubmit() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        const data = {
          uuid: this.field.uuid,
          name: this.field.name,
          inputType: this.field.inputType,
          fieldType: this.field.fieldType,
        };
        fieldSvc
          .update(data)
          .then((resp) => {
            if (resp.status === 200) {
              toast.success("Edited Successfully", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            } else {
              toast.error("Edited Failed", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            }
          })
          .catch((err) => {
            toast.error(err, {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          })
          .finally(() => {
            this.get();
            this.showModalEdit = false;
            this.submitted = false;
          });
      }
    },
    editStatus(uuid) {
      fieldSvc
        .updateStatus(uuid)
        .then((resp) => {
          if (resp.status === 200) {
            toast.success("Update Status Successfully", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          } else {
            toast.error("Update Status Failed", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: localStorage.getItem("toastCountdown"),
          });
        })
        .finally(() => {
          this.get();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12">
                <!-- MODAL CREATE -->
                <div class="text-sm-end">
                  <button
                    type="button"
                    class="btn btn-success btn-rounded mb-2 me-2"
                    @click="create"
                  >
                    <i class="mdi mdi-plus me-1"></i> New Field
                  </button>
                  <b-modal
                    v-model="showModalCreate"
                    title="Add New Field"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                    no-close-on-backdrop
                  >
                    <form @submit.prevent="handleSubmit">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="name">Field Name</label>
                            <input
                              id="name"
                              v-model="field.name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && v$.field.name.$error,
                              }"
                            />

                            <div
                              v-if="submitted && v$.field.name.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="v$.field.name.required.$message">{{
                                v$.field.name.required.$message
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="type">Input Type</label>
                            <b-form inline>
                              <b-form-select
                                class="form-control"
                                v-model="field.inputType"
                                :class="{
                                  'is-invalid':
                                    submitted && v$.field.inputType.$error,
                                }"
                              >
                                <b-form-select-option value=""
                                  >Choose...</b-form-select-option
                                >
                                <b-form-select-option value="text"
                                  >text</b-form-select-option
                                >
                                <b-form-select-option value="number"
                                  >number</b-form-select-option
                                >
                                <b-form-select-option value="date"
                                  >date</b-form-select-option
                                >
                                <!-- <b-form-select-option value="textEditor"
                                  >text editor</b-form-select-option
                                >
                                <b-form-select-option value="image"
                                  >image</b-form-select-option
                                >
                                <b-form-select-option value="album"
                                  >album</b-form-select-option
                                >
                                 -->
                              </b-form-select>
                              <div
                                v-if="submitted && v$.field.inputType.$error"
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="v$.field.inputType.required.$message"
                                  >{{
                                    v$.field.inputType.required.$message
                                  }}</span
                                >
                              </div>
                            </b-form>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="fieldType">Field Type</label>
                            <b-form inline>
                              <b-form-select
                                class="form-control"
                                v-model="field.fieldType"
                                :class="{
                                  'is-invalid':
                                    submitted && v$.field.fieldType.$error,
                                }"
                              >
                                <b-form-select-option value=""
                                  >choose...</b-form-select-option
                                >
                                <b-form-select-option value="default"
                                  >default</b-form-select-option
                                >
                                <b-form-select-option value="optional"
                                  >optional</b-form-select-option
                                >
                              </b-form-select>
                              <div
                                v-if="submitted && v$.field.fieldType.$error"
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="v$.field.fieldType.required.$message"
                                  >{{
                                    v$.field.fieldType.required.$message
                                  }}</span
                                >
                              </div>
                            </b-form>
                          </div>
                        </div>
                      </div>
                      <div class="text-end pt-5 mt-3">
                        <b-button
                          variant="light"
                          @click="showModalCreate = false"
                          >Close</b-button
                        >
                        <b-button type="submit" variant="success" class="ms-1"
                          >Create Field</b-button
                        >
                      </div>
                    </form>
                  </b-modal>
                </div>
                <!-- MODAL CREATE -->

                <!-- MODAL EDIT -->
                <div class="text-sm-end">
                  <b-modal
                    v-model="showModalEdit"
                    :title="`Edit Field : ${field.title}`"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                    no-close-on-backdrop
                  >
                    <form @submit.prevent="handleEditSubmit">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="name">Field Name</label>
                            <input
                              id="uuid"
                              v-model="field.uuid"
                              v-show="false"
                            />
                            <input
                              id="name"
                              v-model="field.name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && v$.field.name.$error,
                              }"
                            />

                            <div
                              v-if="submitted && v$.field.name.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="v$.field.name.required.$message">{{
                                v$.field.name.required.$message
                              }}</span>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label for="type">Input Type</label>
                            <b-form inline>
                              <b-form-select
                                class="form-control"
                                v-model="field.inputType"
                                disabled="true"
                                :class="{
                                  'is-invalid':
                                    submitted && v$.field.inputType.$error,
                                }"
                              >
                                <b-form-select-option value=""
                                  >choose...</b-form-select-option
                                >
                                <b-form-select-option value="text"
                                  >text</b-form-select-option
                                >
                                <b-form-select-option value="number"
                                  >number</b-form-select-option
                                >
                                <b-form-select-option value="date"
                                  >date</b-form-select-option
                                >
                              </b-form-select>
                              <div
                                v-if="submitted && v$.field.inputType.$error"
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="v$.field.inputType.required.$message"
                                  >{{
                                    v$.field.inputType.required.$message
                                  }}</span
                                >
                              </div>
                            </b-form>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="fieldType">Field Type</label>
                            <b-form inline>
                              <b-form-select
                                class="form-control"
                                disabled="true"
                                v-model="field.fieldType"
                                :class="{
                                  'is-invalid':
                                    submitted && v$.field.fieldType.$error,
                                }"
                              >
                                <b-form-select-option value=""
                                  >Choose...</b-form-select-option
                                >
                                <b-form-select-option value="default"
                                  >default</b-form-select-option
                                >
                                <b-form-select-option value="optional"
                                  >optional</b-form-select-option
                                >
                              </b-form-select>
                              <div
                                v-if="submitted && v$.field.fieldType.$error"
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="v$.field.fieldType.required.$message"
                                  >{{
                                    v$.field.fieldType.required.$message
                                  }}</span
                                >
                              </div>
                            </b-form>
                          </div>
                        </div>
                      </div>
                      <div class="text-end pt-5 mt-3">
                        <b-button variant="light" @click="showModalEdit = false"
                          >Close</b-button
                        >
                        <b-button type="submit" variant="warning" class="ms-1"
                          >Update Field</b-button
                        >
                      </div>
                    </form>
                  </b-modal>
                </div>
                <!-- MODAL EDIT -->
              </div>
            </div>
            <div class="table-responsive">
              <vue-good-table
                :search-options="{
                  enabled: true,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: 10,
                  mode: 'records',
                }"
                :line-numbers="true"
                styleClass="tableOne vgt-table striped"
                :columns="columns"
                :rows="rows"
              >
                <template #table-row="props">
                  <span v-if="props.column.field == 'button'">
                    <b-button
                      size="sm"
                      variant="success"
                      @click="edit(props.formattedRow)"
                    >
                      <i
                        class="mdi mdi-pencil font-size-16 align-middle me-2"
                      ></i>
                      Edit
                    </b-button>
                  </span>
                  <span v-else-if="props.column.field == 'fieldType'">
                    <span
                      class="badge rounded-pill font-size-12 badge-soft-primary"
                      v-if="props.formattedRow.fieldType === 'default'"
                      >{{ props.formattedRow.fieldType }}</span
                    >
                    <span
                      class="badge rounded-pill font-size-12 badge-soft-warning"
                      v-else
                      >{{ props.formattedRow.fieldType }}</span
                    >
                  </span>
                  <span v-else-if="props.column.field == 'isActive'">
                    <b-form-checkbox
                      v-model="props.formattedRow.isActive"
                      name="check-button"
                      switch
                      @change="editStatus(props.formattedRow.uuid)"
                    >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-success"
                        v-if="props.formattedRow.isActive"
                        >On</span
                      >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-warning"
                        v-else
                        >Off</span
                      >
                    </b-form-checkbox>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
